import { useState, useEffect } from "react";
import Banner from "../Banner/Banner";
import "./NewsPage.css"



function NewsPage(props) {

    const [viewport, setViewport] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setViewport(document.documentElement.clientWidth);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs only once on mount


    return <>
        <Banner
            class="news-banner"
            bannerImage = {props.bannerImage}
            bannerHeader={props.t(props.header)}
            headerWidth={viewport > 991 ? "45vw" : "85vw"}
        />

        <div className="newspage-container">
            <p className="newspage-paragraph main-text" >{props.t(props.content)}</p>
            <div className='row newspage-inner-container'>
            </div>
            {props.extraElement}
        </div>

    </>

}

export default NewsPage