import { Link } from 'react-router-dom'
import "./Footer.css"

function Footer(props) {
    return <div className="container-fluid" id="footer">
        <div className="row">
            <div className={`col-12 col-lg-5 ${props.t("footer.rights.wrapped")}`} id="footer-inner-1">
                <p>
                    {props.t("footer.rights.part-1")}
                </p>
                <p>
                    {props.t("footer.rights.part-2")}
                </p>
            </div>
            <div className="col-12 col-lg-7" id="footer-inner-2">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z" fill="white" />
                    </svg>
                    <p>
                        {props.t("footer.address")}
                    </p>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.172 13.334L12.002 15.269L14.73 13.387L20.845 19.42C20.684 19.472 20.512 19.5 20.333 19.5H3.667C3.447 19.5 3.237 19.457 3.044 19.38L9.172 13.334ZM22 8.376V17.833C22 18.08 21.946 18.314 21.85 18.525L15.856 12.611L22 8.376ZM2 8.429L8.042 12.561L2.106 18.419C2.0357 18.2316 1.99979 18.0331 2 17.833V8.429ZM20.333 4.5C21.253 4.5 22 5.246 22 6.167V6.753L11.998 13.648L2 6.81V6.167C2 5.247 2.746 4.5 3.667 4.5H20.333Z" fill="white" />
                    </svg>
                    <p>
                        {props.t("footer.email")}
                    </p>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15.5778 16.4639L17.3007 14.741C17.5328 14.5118 17.8264 14.3549 18.1459 14.2894C18.4654 14.2239 18.797 14.2525 19.1005 14.3718L21.2003 15.2102C21.507 15.3347 21.7701 15.5472 21.9562 15.8209C22.1424 16.0947 22.2433 16.4174 22.2463 16.7485V20.5942C22.2446 20.8194 22.1972 21.0419 22.1072 21.2483C22.0171 21.4547 21.8863 21.6408 21.7224 21.7953C21.5586 21.9498 21.3652 22.0695 21.1538 22.1473C20.9425 22.2251 20.7176 22.2593 20.4927 22.2479C5.77891 21.3326 2.81 8.87239 2.24852 4.10368C2.22246 3.8695 2.24627 3.63246 2.3184 3.40814C2.39053 3.18383 2.50933 2.97733 2.66699 2.80223C2.82466 2.62713 3.01761 2.48739 3.23315 2.39222C3.4487 2.29704 3.68195 2.24859 3.91757 2.25003H7.63255C7.96406 2.25101 8.2877 2.35115 8.56183 2.53756C8.83597 2.72397 9.04807 2.98814 9.17085 3.29607L10.0092 5.39584C10.1325 5.69817 10.1639 6.03011 10.0996 6.35021C10.0353 6.6703 9.87815 6.96436 9.64772 7.19565L7.92483 8.91854C7.92483 8.91854 8.91703 15.6332 15.5778 16.4639Z" fill="white" />
                    </svg>
                    <p>
                        {props.t("footer.tel")}
                    </p>
                </div>

            </div>
        </div>
    </div >
}

export default Footer