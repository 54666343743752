import { useState } from 'react';
import "./ContactLink.css";

function ContactLink(props) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        setViewport(document.documentElement.clientWidth);
    });

   
    let iconClasses = `icon ${props.className} ${viewport >= 992 ? "desktop" : "mobile"} `;

    return <a className="link" href={props.href} target="_blank">
        <span className={props.iconBackground}>
            <img
                className="icon"
                src={props.icon}
            >
            </img>
        </span>
        <span id="contact-text">
            <span className="contact-header contact-content">{props.header}</span>
            <span className="contact-content">{props.content}</span>
        </span>
    </a>

}

export default ContactLink;