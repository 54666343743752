import { useState, useEffect } from 'react';
import NavBar from "../../Components/Navbar/Navbar";
import Banner from "../../Components/Banner/Banner";
import Member from '../../Components/Member/Member';
import "./Achievements.css";

function Achievements(props) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setViewport(document.documentElement.clientWidth);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs only once on mount

    return <>
        <Banner
            class="main-banner"
            bannerImage={props.t("banner.images.achievements")}
            bannerHeader={props.t("banner.header.achievements")}
            headerWidth={viewport > 991 ? "27.7604vw" : "90.3308vw"}
            bannerContent={props.t("banner.content.achievements")}
            contentWidth={viewport > 991 ? "36.7188vw" : "90.3308vw"} />

        <div className='achievements-container' id="achievements-graph-container">
            <h3 className='achievements-header'>{props.t("achievements.achievements-graph.header")}</h3>
            <p className="achievements-paragraph">{props.t("achievements.achievements-graph.paragraph")}</p>
            <div id="achievements-graph">
                <img src={viewport > 991 ? props.t("achievements.achievements-graph.image.desktop") : props.t("achievements.achievements-graph.image.mobile")}></img>

            </div>
        </div>
        <div className='achievements-container' id="international-olympiad-2023-2024">
            <h3 className='achievements-header'>{props.t("achievements.2023-2024.header")}</h3>
            <div className="container-fluid ">
                <p className="achievements-paragraph" >{props.t("achievements.2023-2024.paragraph1")}</p>
                <div className='row achievements-inner-container' >
                    <Member
                        image="/images/images/achievements/2023-2024/international/Hakob\ Ayvazyan.png"
                        name={props.t("achievements.2023-2024.members1.name.member1")}
                        content={props.t("achievements.2023-2024.members1.content.member1")}
                        class="col-6 col-lg-2 narrow" />
                    <Member
                        image="/images/images/achievements/2023-2024/international/Davit\ Badishyan.png"
                        name={props.t("achievements.2023-2024.members1.name.member2")}
                        content={props.t("achievements.2023-2024.members1.content.member2")}
                        class="col-6 col-lg-2 narrow"
                        margin={" margin-top"} />
                    <Member
                        image="/images/images/achievements/2023-2024/international/Arman\ Kirakosyan.png"
                        name={props.t("achievements.2023-2024.members1.name.member3")}
                        content={props.t("achievements.2023-2024.members1.content.member3")}
                        class="col-6 col-lg-2 narrow" />
                    <Member
                        image="/images/images/achievements/2023-2024/international/Boris\ Muradyan.png"
                        name={props.t("achievements.2023-2024.members1.name.member4")}
                        content={props.t("achievements.2023-2024.members1.content.member4")}
                        class="col-6 col-lg-2 narrow"
                        margin={"margin-top"} />
                    <Member
                        image="/images/images/achievements/2023-2024/international/Hayk\ Tonoyan.png"
                        name={props.t("achievements.2023-2024.members1.name.member5")}
                        content={props.t("achievements.2023-2024.members1.content.member5")}
                        class="col-6 col-lg-2 narrow" />
                    <Member
                        image="/images/images/achievements/2023-2024/international/Sevada\ Harutyunyan.png"
                        name={props.t("achievements.2023-2024.members1.name.member6")}
                        content={props.t("achievements.2023-2024.members1.content.member6")}
                        class="col-6 col-lg-2 narrow"
                        margin={"margin-top"} />

                </div>
            </div>
        </div>
        <div className='achievements-container' id="ysu-olympiad-2023-2024">

            <div className="container-fluid " >
                <p className="achievements-paragraph" >{props.t("achievements.2023-2024.paragraph2")}</p>
                <div className='row achievements-inner-container' >
                    <Member
                        image="/images/images/achievements/2023-2024/ysu/member1.png"
                        name={props.t("achievements.2023-2024.members2.name.member1")}
                        content={props.t("achievements.2023-2024.members2.content.member1")}
                        class="col-6 col-lg-3 wide" />
                    <Member
                        image="/images/images/achievements/2023-2024/ysu/member2.png"
                        name={props.t("achievements.2023-2024.members2.name.member2")}
                        content={props.t("achievements.2023-2024.members2.content.member2")}
                        class="col-6 col-lg-3 wide"
                        margin={"margin-top"} />
                    <Member
                        image="/images/images/achievements/2023-2024/ysu/member3.png"
                        name={props.t("achievements.2023-2024.members2.name.member3")}
                        content={props.t("achievements.2023-2024.members2.content.member3")}
                        class="col-6 col-lg-3 wide" />
                    <Member
                        image="/images/images/achievements/2023-2024/ysu/member4.png"
                        name={props.t("achievements.2023-2024.members2.name.member4")}
                        content={props.t("achievements.2023-2024.members2.content.member4")}
                        class="col-6 col-lg-3 wide"
                        margin={"margin-top"} />

                </div>
            </div>
        </div>

        <div className='achievements-container' id="olympiad-2022-2023">
            <h3 className='achievements-header'>{props.t("achievements.2022-2023.header")}</h3>
            <div className="container-fluid ">
                <div className='row achievements-inner-container' >
                    <p className='col-12 col-lg-6 achievements-paragraph order-1 order-lg-1'>{props.t("achievements.2022-2023.paragraph1")}</p>
                    <p className='col-12 col-lg-6 achievements-paragraph order-3 order-lg-2'>{props.t("achievements.2022-2023.paragraph2")}</p>
                    <img src="/images/images/achievements/2022-2023/image1.png" className='col-12 col-lg-6 order-2 order-lg-3'></img>
                    <img src="/images/images/achievements/2022-2023/image2.png" className='col-12 col-lg-6 order-4 order-lg-4'></img>
                </div>
            </div>
        </div>

        <div className='achievements-container' id="olympiad-2021-2022">
            <h3 className='achievements-header'>{props.t("achievements.2021-2022.header")}</h3>
            <div className="container-fluid ">
                <div className='row achievements-inner-container' >
                    <p className='achievements-paragraph'>{props.t("achievements.2021-2022.paragraph1")}</p>
                    <img src="/images/images/achievements/2021-2022/image1.png" ></img>
                    <ul className='achievements-paragraph achievements-list' >
                        {props.t("achievements.2021-2022.paragraph2.content1")}
                        <li>{props.t("achievements.2021-2022.paragraph2.list-item-1")}</li>
                        <li>{props.t("achievements.2021-2022.paragraph2.list-item-2")}</li>
                        <li>{props.t("achievements.2021-2022.paragraph2.list-item-3")}</li>

                        {props.t("achievements.2021-2022.paragraph2.content2")}
                        <li>{props.t("achievements.2021-2022.paragraph2.list-item-4")}</li>
                    </ul>
                </div>
            </div>
        </div>

    </>
}

export default Achievements;