import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css';

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
  arrows: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1.23,
        slidesToScroll: 1,
        
      }
    }
  ]
};

const Carousel = (props) => {
  const [viewport, setViewport] = useState(document.documentElement.clientWidth);

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setViewport(document.documentElement.clientWidth);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures this runs only once on mount


  return (viewport > 991 ?
    <>
      <div className="carousel-container">
        <Slider {...settings}>
          <div className="carousel-item">
            <CarouselItemInner t={props.t} line={"line-1"} member={"student-1"} />
          </div>
          <div className="carousel-item">
            <CarouselItemInner t={props.t} line={"line-1"} member={"student-1"} />
          </div>
          <div className="carousel-item">
            <CarouselItemInner t={props.t} line={"line-1"} member={"student-1"} />
          </div>
          <div className="carousel-item">
            <CarouselItemInner t={props.t} line={"line-1"} member={"student-1"} />
          </div>

        </Slider>
      </div>
      <div className="carousel-container">
        <Slider {...settings}>
          <div className="carousel-item extra-margin">
            <CarouselItemInner t={props.t} line={"line-2"} member={"student-1"} />
          </div>
          <div className="carousel-item extra-margin">
            <CarouselItemInner t={props.t} line={"line-2"} member={"student-1"} />
          </div>
          <div className="carousel-item extra-margin">
            <CarouselItemInner t={props.t} line={"line-2"} member={"student-1"} />
          </div>
          <div className="carousel-item extra-margin">
            <CarouselItemInner t={props.t} line={"line-2"} member={"student-1"} />
          </div>
        </Slider>
      </div>
    </> :
    <div className="carousel-container">
      <Slider {...settings}>
        <div className="carousel-item">
          <CarouselItemInner t={props.t} line={"line-1"} member={"student-1"} />
        </div>
        <div className="carousel-item">
          <CarouselItemInner t={props.t} line={"line-1"} member={"student-1"} />
        </div>
        <div className="carousel-item">
          <CarouselItemInner t={props.t} line={"line-1"} member={"student-1"} />
        </div>
        <div className="carousel-item">
          <CarouselItemInner t={props.t} line={"line-1"} member={"student-1"} />
        </div>
        <div className="carousel-item ">
          <CarouselItemInner t={props.t} line={"line-2"} member={"student-1"} />
        </div>
        <div className="carousel-item ">
          <CarouselItemInner t={props.t} line={"line-2"} member={"student-1"} />
        </div>
        <div className="carousel-item ">
          <CarouselItemInner t={props.t} line={"line-2"} member={"student-1"} />
        </div>
        <div className="carousel-item ">
          <CarouselItemInner t={props.t} line={"line-2"} member={"student-1"} />
        </div>
      </Slider>
    </div>
  );
};

function CarouselItemInner(props) {

  return <div className="carousel-item-inner">
    {/* <div className='carousel-item-name'>
      <img src={`images/images/home/what-do-our-students-say/${props.line}/${props.member}.webp`}></img>
      <p>{props.t(`home.what-do-our-students-say.name.${props.line}.${props.member}`)}</p>
    </div>
    <p className='carousel-item-content'>{props.t(`home.what-do-our-students-say.content.${props.line}.${props.member}`)}</p> */}
  </div>
}

export default Carousel;
