import { useState, useEffect } from 'react';
import "./Button.css";


function Button(props) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setViewport(document.documentElement.clientWidth);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs only once on mount


    return <button className="store-button">

        <span className="store-icon" alt="">
            {props.storeIcon}
        </span>
        <span className="download-text">
            <span className="text">{props.accompanyingText}</span>
            <span className="text store">{props.storeName}</span>
        </span>
        <span className="qr" alt="">
            {props.qrIcon}
        </span>

    </button>


}

export default Button;