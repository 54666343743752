import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Banner from "../../Components/Banner/Banner";
import ContactLink from "../../Components/ContactLink/ContactLink";
import InputField from "../../Components/InputField/InputField";
import "./Contact_us.css";


function ContactUs(props) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setViewport(document.documentElement.clientWidth);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs only once on mount

    const [inputText, setInputText] = useState("");

    const [input_email, setInput_email] = useState("");
    const [input_name, setInput_name] = useState("");
    // const [input_phone, setInput_phone] = useState("");



    document.addEventListener('invalid', ((e) => {
        return (e) => {
            //prevent the browser from showing default error bubble / hint
            e.preventDefault();
        };
    })(), true);

    function validityMessageShow(e) {
        let validityMessage = (e?.target.parentElement).nextElementSibling.nextElementSibling;
        validityMessage.innerHTML = "Please fill out this field";

        validityMessage.style.display = "block";

    }

    function validityMessageHide(e) {
        let validityMessage = (e?.target.parentElement).nextElementSibling.nextElementSibling;
        validityMessage.innerHTML = "";
        validityMessage.style.display = "none";
    }
    // function nameValidation(input) {
    //     const regex = /^[a-zA-Z]+$/;
    //     return regex.test(input.replace(/\s+/g, ""));
    // }
    function nameValidation(input) {
        const regex = /^[a-zA-Z\u0531-\u0587]+$/;
        return regex.test(input.replace(/\s+/g, ""));
    }
    
    // function phoneValidation(input) {
    //     const phoneNumber = parsePhoneNumberFromString(input);
    //     return phoneNumber && phoneNumber.isValid;
    // }
    function phone_nameValidation() {
        const nameInput = document.getElementById("name");
        let bothAreValid = true;

        if (!nameValidation(nameInput.value)) {
            let nameValidityMessage = (nameInput.nextElementSibling).nextElementSibling;
            nameValidityMessage.innerHTML = "Please enter a valid name";
            nameValidityMessage.style.display = "block";
            bothAreValid = false;
        }

        // const phoneInput = document.getElementById("phone");
        // if (!phoneValidation(phoneInput.value)) {
        //     let validityMessage = (phoneInput.nextElementSibling).nextElementSibling;
        //     validityMessage.innerHTML = "Please enter a valid phone number";
        //     validityMessage.style.display = "block";
        //     bothAreValid = false;
        // }
        return bothAreValid;

    }


    function handleSubmit(e) {

        e.preventDefault();
        if (!phone_nameValidation()) {
            return;
        }

        let inputs = document.getElementsByClassName("inputField");

        Array.from(inputs).forEach(input => {
            if (!(input.id === "text-message-container")) {
                input.value = "";
            } else {
                let textMessage = input.firstElementChild;
                textMessage.value = "";
            }
        });
        setInputText("");
        setInput_email("");
        setInput_name("");
        // setInput_phone("");

        let validityMessages = document.getElementsByClassName("validity-message");
        Array.from(validityMessages).map((message) => {
            message.innerHTML = "";
            message.style.display = "none";
        })

        document.getElementById("text-message").style.height = ""  // reset size 
    }

    function handleTextMessageFocus(e) {
        setInputText("input");
        e.target.parentElement.style.border = `1px solid var(--Primary,  #3949C9)`;
        // e.target.style.color = "#1F2D9E"
    }

    function inputBlur(e) {
        if (e.target.value.trim() === "") {
            e.target.value = ""
            e.target.style.height = "";
        }
        setInputText(e.target.value);
        if (e.target.id === "text-message") {
            e.target.parentElement.style.border = "1px solid var(--Primary, #5F71FE)";
        }
    }

    function textMessageExpand(e) {
        validityMessageHide(e);
        // e.target.style.height = 'auto'; 
        // Reset the height;
        // e.target.style.height = (e.target.scrollHeight) + 'px'; 
        // Set to the scroll height
    }


    function addPhonePlusSymbol(e) {
        if (e.target.value.indexOf('+') !== 0) {
            e.target.value = '+' + e.target.value.replace(/\+/g, '');
        }
    };

    function removePhonePlusSymbol(e) {
        if (e.target.value === "+" || e.target.value.trim() === "+") {
            e.target.value = "";
            // setInput_phone("");
        }
    };

    function handleFormFocus(e) {
        const inputs = document.querySelectorAll('input, textarea'); // Include textareas

        inputs.forEach(input => {
            input.addEventListener('input', () => {

                // if (input.matches(':-webkit-autofill') || input.matches(':autofill')) {

                if (input.id === "email") {
                    setInput_email(input.value)
                } else if (input.id === "name") {
                    setInput_name(input.value)
                }
                // else if (input.id === "phone") {
                //     setInput_phone(input.value)
                // } 
                else if (input.id === "text-message") {
                    setInputText(input.value)
                }
                // }
            })


        })
    }

    let textMessageClasses = `text-message ${inputText !== "" ? "input-present" : ""}}`
    let placeholderClasses = `placeholder ${inputText !== "" ? "input-present" : ""} ${viewport >= 992 ? "desktop" : "mobile"} }`

    return <>
        <Banner
            class="main-banner"
            bannerImage="images/banner/banner images/contact-us.webp"
            bannerHeader={props.t("banner.header.contactUs")}
            headerWidth={viewport > 991 ? "27.2396vw" : "90.3308vw"}
            bannerContent={props.t("banner.content.contactUs")}
            contentWidth={viewport > 991 ? "36.7188vw" : "90.3308vw"} />


        <div className="container-fluid contact-us-container">
            <div className="row inn-row">
                <div className="contact col12 col-lg-4 ">
                    <ContactLink
                        iconBackground={"icon-circle"}
                        href={""}
                        icon={"./images/images/contact-us/address.svg"}
                        header={props.t("contact-us.contacts.address.header")}
                        content={props.t("contact-us.contacts.address.content")}
                    />
                    <ContactLink
                        iconBackground={"icon-circle"}
                        href={"tel:+374 41 354 551"}
                        icon={"./images/images/contact-us/phone.svg"}
                        header={props.t("contact-us.contacts.phone.header")}
                        content={props.t("contact-us.contacts.phone.content")}
                    />
                    <ContactLink
                        iconBackground={"icon-circle"}
                        href={"mailto: info@improvismail.com"}
                        icon={"./images/images/contact-us/email.svg"}
                        header={props.t("contact-us.contacts.email.header")}
                        content={props.t("contact-us.contacts.email.content")}
                    />

                </div>
                <form className="form col12 col-lg-8" onSubmit={handleSubmit} id="contact-form" action='' method='' onFocus={handleFormFocus}>
                    <p className="head contact-text">
                        {props.t("contact-us.form.header")}
                    </p>
                    <InputField type={`text`} placeholder={props.t("contact-us.form.placeholders.name")} id={"name"} setInput={setInput_name} inputText={input_name} />
                    <InputField type={`email`} placeholder={props.t("contact-us.form.placeholders.email")} id={"email"} setInput={setInput_email} inputText={input_email} />
                    <div className='group'>
                        <div className="inputField" id="text-message-container" onClick={(e) => {
                           e.target.firstElementChild?.focus();
                            
                            }}>
                            <textarea
                                className={textMessageClasses}
                                id="text-message"
                                onFocus={handleTextMessageFocus}
                                onInput={textMessageExpand}
                                onInvalid={validityMessageShow}
                                onBlur={inputBlur}
                                required
                            />
                        </div>
                        <label className={placeholderClasses}>{props.t("contact-us.form.placeholders.message")}</label>
                        <span className="validity-message  validity-message-textarea"></span>
                    </div>
                    <div className="bottom">
                        <input className="submit-button" type="submit" value={props.t("contact-us.form.button")} onClick={phone_nameValidation} />
                        <span className='icon-group'>
                            <ContactLink
                                href={"https://am.linkedin.com/company/improvis"}
                                icon={"./images/images/contact-us/linkedin.svg"}
                                // className={"rounded"}
                                name={"Linkedin"}
                                animated={true} />
                            <ContactLink
                                href={"https://www.facebook.com/improvisteam/"}
                                icon={"./images/images/contact-us/facebook.svg"}
                                // className={"rounded"}
                                name={"Facebook"}
                                animated={true} />
                            <ContactLink
                                href={"https://improvis.ai/home"}
                                icon={"./images/images/contact-us/Improvis.svg"}
                                // className={"rounded"}
                                name={"Improvis"}
                                animated={true} />
                        </span>
                       
                    </div>
                </form>

            </div>
        </div>

        <div className="contact-us-container" id="map-container">
            <h3 className='contact-us-header' id="home-graph-header">{props.t("contact-us.map.head")}</h3>
            <iframe id="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2359.6483948384066!2d43.83779185547799!3d40.786314740938316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sam!4v1734990554685!5m2!1sru!2sam"
                allowFullScreen=""
                loading="lazy"
            />
        </div>
    </>
}

export default ContactUs;