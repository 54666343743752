import { useState, useEffect } from 'react';
import Banner from "../../Components/Banner/Banner";
import "./Become_a_student.css"

function BecomeAStudent(props) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setViewport(document.documentElement.clientWidth);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs only once on mount

    return <>
        <Banner
            class="main-banner"
            bannerImage="images/banner/banner images/become-a-student.webp"
            bannerHeader={props.t("banner.header.becomeAStudent")}
            headerWidth={viewport > 991 ? "39.5313vw" : "90.3308vw"}
            bannerContent={props.t("banner.content.becomeAStudent")}
            contentWidth={viewport > 991 ? "36.7188vw" : "90.3308vw"}
        />
        <div className='for-space'></div>
    </>
}

export default BecomeAStudent;