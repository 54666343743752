import "./Member.css"


function Member(props) {

    return <>
        <div className={`${props.class} member-container `} >
            <div className={`member-inner-container ${props.margin}`}>
                <div className="member-image" style={{
                    backgroundImage: `url(${props.image})`,
                }}></div>
                <div>
                    <p className="member-name member-content">{props.name}</p>
                    <p className="member-content">{props.content}</p>
                </div>

            </div>
        </div>
    </>

}

export default Member;