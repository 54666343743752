// import { changeLanguage } from 'i18next';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Wrapper from './Components/Wrapper/Wrapper';
import Home from './Pages/Home/Home';
import Achievements from './Pages/Achievements/Achievements';
import News from './Pages/News/News';
import Mentors from './Pages/Mentors/Mentors';
import ContactUs from './Pages/Contact us/Contact_us';
import BecomeAStudent from './Pages/Become a student/Become_a_student';
import NavBar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import NewsPage from './Components/NewsPage/NewsPage';



function App() {

  const [viewport, setViewport] = useState(document.documentElement.clientWidth);

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setViewport(document.documentElement.clientWidth);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures this runs only once on mount


  const { t, i18n } = useTranslation();


  return (
    <>
      <BrowserRouter>
        <NavBar i18n={i18n} t={t} />
        <Wrapper>
          <Routes>
            <Route index element={<Home i18n={i18n} t={t} />} />
            <Route path="/achievements" element={<Achievements i18n={i18n} t={t} />} />
            <Route path="/news" element={<News i18n={i18n} t={t} />} />
            <Route path="/mentors" element={<Mentors i18n={i18n} t={t} />} />
            <Route path="/contact-us" element={<ContactUs i18n={i18n} t={t} />} />
            <Route path="/become-a-student" element={<BecomeAStudent i18n={i18n} t={t} />} />
            <Route
              path={`/news/Arman Kirakosyan Wins Bronze at European Junior Olympiad`}
              element={
                <NewsPage
                  bannerImage={"/images/banner/banner images/newspages/news1.png"}
                  header={t("news.news.news-header.news1")}
                  content={t("news.news.content.news1")}
                  // extraElement={<iframe style={{ "width": "100%", "aspectRatio": "16/9" }}
                  //   src="https://www.youtube-nocookie.com/embed/iv9ofW_mL_w?&modestbranding=1&controls=1&rel=0&showinfo=0&autoplay=0&mute=1&playlist=iv9ofW_mL_w"
                  // ></iframe>}
                  i18n={i18n}
                  t={t} />} />
            <Route
              path={`/news/Additional Math Courses Announced for Exam Applicants`}
              element={
                <NewsPage
                  bannerImage={"/images/banner/banner images/newspages/news2.png"}
                  header={t("news.news.news-header.news2")}
                  content={t("news.news.content.news2")}
                  extraElement={
                    <p className='sincerely'>{t("news.news.extra.news2.sincerely")}</p>
                  }
                  i18n={i18n}
                  t={t} />} />
            <Route
              path={`/news/Written stage of the 2024-2025 Improvis Olympic Center admission exam`}
              element={

                <NewsPage
                  bannerImage={"/images/banner/banner images/newspages/news3.png"}
                  header={t("news.news.news-header.news3")}
                  content={t("news.news.content.news3")}
                  extraElement={
                    <div className='container-fluid'>
                      <div className='row'>
                        <div className='col-12 col-lg-4 pl-3 pr-3 mt-3 mb-3'>
                          <div style={{
                            "borderRadius": `${viewport > 991 ? "0.7813vw" : "3.8168vw"}`,
                            "background": "#FFF",
                            "boxShadow": "0px 0px 15px 0px rgba(95, 113, 254, 0.25)",
                            "padding": `${viewport > 991 ? "1.0416vw" : "3.3078vw"}`,
                            "display": "flex",
                            "alignItems": "center",
                            "justifyContent": `${viewport > 991 ? "center" : "start"}`,
                            "columnGap": `${viewport > 991 ? "1.25vw" : "4.0712vw"}`
                          }}>
                            <img src="/images/images/news/news3/Date.svg" style={{ "width": "23.3%", "aspectRatio": "1:1" }}></img>
                            <p className='newspage-paragraph' style={{ "fontSize": `${viewport > 991 ? "2.1875vw" : "6.1068vw"}`,  "margin" : "0", "color": "#5F71FE" }}>{t("news.news.extra.news3.date")}</p>
                          </div>
                        </div>
                        <div className='col-12 col-lg-4 pl-3 pr-3 mt-3 mb-3'>
                          <div style={{
                            "borderRadius": `${viewport > 991 ? "0.7813vw" : "3.8168vw"}`,
                            "background": "#FFF",
                            "boxShadow": "0px 0px 15px 0px rgba(95, 113, 254, 0.25)",
                            "padding": `${viewport > 991 ? "1.0416vw" : "3.3078vw"}`,
                            "display": "flex",
                            "alignItems": "center",
                            "justifyContent": `${viewport > 991 ? "center" : "start"}`,
                            "columnGap": `${viewport > 991 ? "1.25vw" : "4.0712vw"}`
                          }}>
                            <img src="/images/images/news/news3/Hour.svg" style={{ "width": "23.3%", "aspectRatio": "1:1" }}></img>
                            <p className='newspage-paragraph' style={{ "fontSize": `${viewport > 991 ? "2.1875vw" : "6.1068vw"}`,  "margin" : "0", "color": "#5F71FE" }}>{t("news.news.extra.news3.time")}</p>
                          </div>
                        </div>
                        <div className='col-12 col-lg-4 pl-3 pr-3 mt-3 mb-3'>
                          <div style={{
                            "borderRadius": `${viewport > 991 ? "0.7813vw" : "3.8168vw"}`,
                            "background": "#FFF",
                            "boxShadow": "0px 0px 15px 0px rgba(95, 113, 254, 0.25)",
                            "padding": `${viewport > 991 ? "1.0416vw" : "3.3078vw"}`,
                            "display": "flex",
                            "alignItems": "center",
                            "justifyContent": `${viewport > 991 ? "center" : "start"}`,
                            "columnGap": `${viewport > 991 ? "1.25vw" : "4.0712vw"}`
                          }}>
                            <img src="/images/images/news/news3/Place.svg" style={{ "width": "23.3%", "aspectRatio": "1:1" }}></img>
                            <p className='newspage-paragraph' style={{ "fontSize": `${viewport > 991 ? "2.1875vw" : "6.1068vw"}`,  "margin" : "0", "color": "#5F71FE" }}>{t("news.news.extra.news3.place")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  i18n={i18n}
                  t={t} />} />
            <Route
              path={`/news/Sevada Harutyunyan was awarded at the republican stage of the Mathematics Olympiad`}
              element={
                <NewsPage
                  bannerImage={"/images/banner/banner images/newspages/news4.png"}
                  header={t("news.news.news-header.news4")}
                  content={t("news.news.content.news4")}
                  i18n={i18n}
                  t={t} />} />
          </Routes>
        </Wrapper>
        <Footer t={t} />
      </BrowserRouter>
    </>
  );
}

function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  )

}

export default WrappedApp;