import { useState } from 'react';
import "./InputField.css"

function InputField(props) {

    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });
 

    function validityMessageShow(e) {

        let validityMessage = (e?.target.nextElementSibling).nextElementSibling;
       

        if (!e.target.value ) {

            validityMessage.innerHTML = "Please fill out this field";
        }
       

         else if (e.target.id === "email") {
            validityMessage.innerHTML = "Please enter a valid email address";
        }
        validityMessage.style.display = "block";

    }
    function handleFocus(e) {
        if(props.addPlusSymbol) {
            props.addPlusSymbol(e);
        }
    }

    function handleInput(e) {
        if(e.target.id === "phone"){
            if(e.target.value .trim()== "" || e.target.value.isEmpty ){
                e.target.value = '+';
            }
        }
        validityMessageHide(e);

    }

    function validityMessageHide(e) {

        let validityMessage = (e?.target.nextElementSibling).nextElementSibling;
        validityMessage.innerHTML = "";
        validityMessage.style.display = "none";

    }


    function inputBlur(e) {
        if (e.target.value.trim() === "") {
            e.target.value = ""
        }
        props.setInput(e.target.value);
        if (props.id === "phone") {
            props.removePlusSymbol(e);
        }
    }

    function enterKeySet(e) {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    }


    let inputClasses = `inputField ${props.inputText !== "" ? "input-present" : ""} ${viewport >= 992 ? "desktop" : "mobile"} `
    let placeholderClasses = `placeholder ${props.inputText !== "" ? "input-present" : ""} ${viewport >= 992 ? "desktop" : "mobile"} }`


    return <div className='group'>
        <input
            className={inputClasses}
            id={props.id}
            type={props.type}
            onKeyDown={enterKeySet}
            onInput={handleInput}
            onFocus={handleFocus}
            onBlur={inputBlur}
            onInvalid={validityMessageShow}
            required></input>
        <label className={placeholderClasses}>{props.placeholder}</label>
        <span className="validity-message"></span>
    </div>
}

export default InputField;