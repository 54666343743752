import { useState, useEffect } from 'react';
import NavBar from "../../Components/Navbar/Navbar";
import Banner from "../../Components/Banner/Banner";
import Member from '../../Components/Member/Member';
import "./Mentors.css"

function Mentors(props) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setViewport(document.documentElement.clientWidth);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs only once on mount

    return <>
        <Banner
            class="main-banner"
            bannerImage="images/banner/banner images/mentors.webp"
            bannerHeader={props.t("banner.header.mentors")}
            headerWidth={viewport > 991 ? "39.5313vw" : "90.3308vw"}
            bannerContent={props.t("banner.content.mentors")}
            contentWidth={viewport > 991 ? "36.7188vw" : "90.3308vw"}
        />

        <div className='mentors-container' id="board-of-trustees-container">
            <h3 className='mentors-header'>{props.t("mentors.board-of-trustees.header")}</h3>
            <div className="container-fluid ">
                <div className='row mentors-inner-container' >
                    <Member
                        image=""
                        name={props.t("mentors.board-of-trustees.name.member1")}
                        content={props.t("mentors.board-of-trustees.content.member1")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/board-of-trustees/Levon\ Sekhposyan.png"
                        name={props.t("mentors.board-of-trustees.name.member2")}
                        content={props.t("mentors.board-of-trustees.content.member2")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/board-of-trustees/Manyak\ Mutafyan.png"
                        name={props.t("mentors.board-of-trustees.name.member3")}
                        content={props.t("mentors.board-of-trustees.content.member3")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/board-of-trustees/Aram\ Baghiyan.png"
                        name={props.t("mentors.board-of-trustees.name.member4")}
                        content={props.t("mentors.board-of-trustees.content.member4")}
                        class="col-6 col-lg-3 circle" />
                </div>

            </div>
        </div>

        <div className='mentors-container' id="mentors-container">
            <h3 className='mentors-header'>{props.t("mentors.mentors.header")}</h3>
            <div className="container-fluid ">
                <div className='row mentors-inner-container' >
                    <Member
                        image="/images/images/mentors/mentors/member1.png"
                        name={props.t("mentors.mentors.name.member1")}
                        content={props.t("mentors.mentors.content.member1")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/mentors/member2.png"
                        name={props.t("mentors.mentors.name.member2")}
                        content={props.t("mentors.mentors.content.member2")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/mentors/member3.png"
                        name={props.t("mentors.mentors.name.member3")}
                        content={props.t("mentors.mentors.content.member3")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/mentors/member4.png"
                        name={props.t("mentors.mentors.name.member4")}
                        content={props.t("mentors.mentors.content.member4")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/mentors/member5.png"
                        name={props.t("mentors.mentors.name.member5")}
                        content={props.t("mentors.mentors.content.member5")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/mentors/member6.png"
                        name={props.t("mentors.mentors.name.member6")}
                        content={props.t("mentors.mentors.content.member6")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/mentors/member7.png"
                        name={props.t("mentors.mentors.name.member7")}
                        content={props.t("mentors.mentors.content.member7")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/mentors/member8.png"
                        name={props.t("mentors.mentors.name.member8")}
                        content={props.t("mentors.mentors.content.member8")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/mentors/member9.png"
                        name={props.t("mentors.mentors.name.member9")}
                        content={props.t("mentors.mentors.content.member9")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/mentors/member10.png"
                        name={props.t("mentors.mentors.name.member10")}
                        content={props.t("mentors.mentors.content.member10")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/mentors/member11.png"
                        name={props.t("mentors.mentors.name.member11")}
                        content={props.t("mentors.mentors.content.member11")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/mentors/member12.png"
                        name={props.t("mentors.mentors.name.member12")}
                        content={props.t("mentors.mentors.content.member12")}
                        class="col-6 col-lg-3 circle" />
                    <Member
                        image="/images/images/mentors/mentors/member13.png"
                        name={props.t("mentors.mentors.name.member13")}
                        content={props.t("mentors.mentors.content.member13")}
                        class="col-6 col-lg-3 circle" />
                </div>
            </div>

        </div>
    </>




}

export default Mentors;