import { useState, useEffect } from 'react';
import NavBar from "../../Components/Navbar/Navbar";
import Banner from "../../Components/Banner/Banner";
import NewsCard from '../../Components/NewsCard/NewsCard';
import "./News.css"

function News(props) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setViewport(document.documentElement.clientWidth);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs only once on mount

    return <>
        <Banner
            class="main-banner"
            bannerImage="images/banner/banner images/news.webp"
            bannerHeader={props.t("banner.header.news")}
            headerWidth={viewport > 991 ? "34.2188vw" : "90.3308vw"}
            bannerContent={props.t("banner.content.news")}
            contentWidth={viewport > 991 ? "34.7395vw" : "90.3308vw"} 
        />

        <div className='news-container' id="news">
            <h3 className='news-header'>{props.t("news.news.header")}</h3>
            <div className="container-fluid" >
                <div className='row' id = 'news-inner-container'>
                    <NewsCard href = {`/news/Arman Kirakosyan Wins Bronze at European Junior Olympiad`} t = {props.t} header = "news.news.news-header.news1" date = "news.news.date.news1" image={"/images/news-card/news1.png"} class="col-6 col-lg-3" />
                    <NewsCard  href = {`/news/Additional Math Courses Announced for Exam Applicants`} t = {props.t} header = "news.news.news-header.news2" date = "news.news.date.news2" image= {"/images/news-card/news2.png"} class="col-6 col-lg-3" />
                    <NewsCard  href = {`/news/Written stage of the 2024-2025 Improvis Olympic Center admission exam`} t = {props.t} header = "news.news.news-header.news3" date = "news.news.date.news3" image={"/images/news-card/news3.png"} class="col-6 col-lg-3" />
                    <NewsCard href = {`/news/Sevada Harutyunyan was awarded at the republican stage of the Mathematics Olympiad`} t  = {props.t} header = "news.news.news-header.news4" date = "news.news.date.news4" image={"/images/news-card/news4.png"} class="col-6 col-lg-3" />
                </div>
            </div>
        </div>
    </>

}

export default News;